<template>
  <div class="article-page-color-container" v-click-outside="() => toggle(false)" :class="{active}">
    <button class="article-page-color-btn" @click="toggle()">
      <span class="yellow"></span><span class="white"></span><span class="green"></span><span class="blue"></span>
    </button>
    <ul class="article-page-color-list">
      <li class="article-page-color-item" @click="setColor(color)" v-for="({color, text}, idx) in list" :key="`color-${idx}`">
        <span class="article-page-color" :style="{background: color, border: color === '#FFFFFF' ? '1px solid #E3EEF6': null}"></span>
        <span class="article-page-color-text">{{text}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "ArticleColorsDropdown",
  data(){
    return {
      active: false,
      list: [
        {
          color: '#fffcb0',
          text: 'yellow'
        },
        {
          color: '#97f1d2b3',
          text: 'green'
        },
        {
          color: '#a7cbffb3',
          text: 'blue'
        },
        {
          color: '#cc94ff80',
          text: 'purple'
        },
        {
          color: '#fcb2b980',
          text: 'red'
        },
        {
          color: '#FFFFFF',
          text: 'white'
        }
      ]
    }
  },
  directives: {
    ClickOutside
  },
  methods: {
    toggle(bool = null) {
      this.active = bool === null ? !this.active : bool;
    },
    setColor(color){
      this.$emit('selected', color);
      this.toggle();
    }
  }
}
</script>

<style scoped>

</style>
